// @flow
import * as React from 'react';
import {Chip} from "@mui/material";
import {useContext} from "react";
import {SettingsContext} from "../contexts/SettingsContext";

type Props = {
    text: string;
};

export default function ColorizedChip(props: Props) {
    const {color} = useContext(SettingsContext);

    return <Chip label={props.text} variant="outlined" sx={{
        borderRadius: 2,
        fontSize: 18,
        fontWeight: 'bold',
        borderWidth: 4,
        borderColor: color,
        transition: '.4s',
        paddingY: 2
    }} />
};