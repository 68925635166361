// @flow
import * as React from 'react';
import {styled, Typography, TypographyProps} from "@mui/material";

const CustomizedTypography = styled(Typography)`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 40%;
    background: ${props => props.theme.palette.primary.main};
    bottom: 0;
    left: 0;
    transition: height .2s ease-in-out, background .4s;
    z-index: -10;
  }

  &:hover::before {
    height: 100%;
  }
`

export default function ColorizedText({children, ...props}: TypographyProps) {
    return <CustomizedTypography {...props}>{children}</CustomizedTypography>
};
