import {PaletteMode} from "@mui/material";
import React, {ReactNode, useState} from "react";

export enum ISettingsColor {
    FIRST = 'rgba(101,93,138,0.4)',
    SECOND = 'rgba(120,151,171,0.4)',
    THIRD = 'rgba(216,133,163,0.4)',
    FOURTH = 'rgba(253,206,185,0.4)',
    FIFTH = 'rgba(84,186,185,0.4)'
};

interface ISettingsContext {
    mode: PaletteMode
    color: ISettingsColor,
    toggleMode: (mode: PaletteMode) => void
    setColor: (color: ISettingsColor) => void
}

const initialValues: ISettingsContext = {
    mode: 'light',
    color: ISettingsColor.FIFTH,
    toggleMode: () => {
    },
    setColor: () => {
    }
}

export const SettingsContext = React.createContext<ISettingsContext>(initialValues);

type SettingsProviderProps = {
    children: ReactNode;
};

export function SettingsProvider({children}: SettingsProviderProps) {
    const [settings, setSettings] = useState({
        ...initialValues,
        toggleMode: (mode: PaletteMode) => {
            setSettings({
                ...settings,
                mode: mode === 'light' ? 'dark' : 'light'
            })
        },
        setColor: (color: ISettingsColor) => {
            setSettings({
                ...settings,
                color
            })
        }
    });
    return <SettingsContext.Provider value={{
        ...settings,
    }}>{children}</SettingsContext.Provider>
}

