import React, { useContext, useEffect } from "react";
import "./App.css";
import {
    Avatar,
    Box,
    Container,
    createTheme,
    Grid,
    Link,
    Stack,
    styled,
    ThemeProvider,
    Typography,
} from "@mui/material";
import Cube from "./components/Cube";
import ColorizedText from "./components/ColorizedText";
import { ISettingsColor, SettingsContext } from "./contexts/SettingsContext";
import PirateLogo from "./images/pirate-logo.png";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ColorizedChip from "./components/ColorizedChip";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const InlineTypography = styled(Typography)`
    display: inline;
`;

const skills = [
    "Docker",
    "Python",
    "Go",
    "Node.js",
    "Typescript",
    "React",
    "PostgreSQL",
    "Redis/MongoDB",
    "AWS",
    "Gitlab CI/CD",
];

const firebaseConfig = {
    apiKey: "AIzaSyCSbhXy16a6qGhTHQ7KFuHjIePA2kj9MSw",
    authDomain: "ntqk-87eb5.firebaseapp.com",
    projectId: "ntqk-87eb5",
    storageBucket: "ntqk-87eb5.appspot.com",
    messagingSenderId: "487469762876",
    appId: "1:487469762876:web:89919533b4857db2733639",
    measurementId: "G-8ZBEMZV90Z",
};

function App() {
    const { color, setColor } = useContext(SettingsContext);
    useEffect(() => {
        const app = initializeApp(firebaseConfig);
        getAnalytics(app);
    }, []);
    const theme = createTheme({
        typography: {
            fontFamily: [
                "Montserrat",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(","),
        },
        components: {
            MuiLink: {
                defaultProps: {
                    underline: "none",
                    color: "#000",
                },
            },
            MuiTypography: {
                defaultProps: {},
            },
        },
        palette: {
            primary: {
                main: color,
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ py: 4 }} maxWidth={"lg"} fixed>
                <Stack
                    direction={"row"}
                    flexWrap={"wrap"}
                    justifyContent={"space-between"}
                    rowGap={2}
                    alignItems={"top"}
                    marginBottom={2}
                >
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <EmailOutlinedIcon />
                        <Typography
                            variant={"h6"}
                            textAlign={"center"}
                            fontWeight={"bold"}
                        >
                            khoinguyenit15@gmail.com
                        </Typography>
                    </Stack>

                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <LinkedInIcon />
                        <Typography
                            variant={"h6"}
                            textAlign={"center"}
                            fontWeight={"bold"}
                        >
                            <Link
                                href="https://www.linkedin.com/in/ntqk/"
                                target="_blank"
                            >
                                linkedin.com/in/ntqk/
                            </Link>
                        </Typography>
                    </Stack>

                    <Stack spacing={1}>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            {Object.keys(ISettingsColor).map((c) => {
                                const colorValue =
                                    ISettingsColor[
                                    c as keyof typeof ISettingsColor
                                    ];
                                return (
                                    <Avatar
                                        key={c}
                                        sx={{
                                            backgroundColor: colorValue,
                                            opacity:
                                                colorValue === color ? 1 : 0.6,
                                            boxShadow:
                                                colorValue === color
                                                    ? `${colorValue} 0px 5px 15px;`
                                                    : "none",
                                            transition: ".4s",
                                        }}
                                        onClick={() => {
                                            setColor(colorValue);
                                        }}
                                    >
                                        {""}
                                    </Avatar>
                                );
                            })}
                        </Stack>
                    </Stack>
                </Stack>
                <Grid container columnSpacing={4}>
                    <Grid item xs>
                        <Box sx={{ mb: 4 }}>
                            <Stack
                                direction={"row"}
                                spacing={2}
                                sx={{ alignItems: "flex-end" }}
                            >
                                <Typography variant={"h1"} fontWeight={"bold"}>
                                    Hi.
                                </Typography>
                                <Avatar
                                    src={PirateLogo}
                                    sx={{ width: 150, height: 150, pb: 1.8 }}
                                />
                            </Stack>
                            <InlineTypography
                                variant={"h3"}
                                fontWeight={"bold"}
                            >
                                My name is&nbsp;
                            </InlineTypography>
                            <ColorizedText
                                variant={"h3"}
                                display={"inline-block"}
                            >
                                {"Lee."}
                            </ColorizedText>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <InlineTypography variant={"h4"}>
                                I'm a
                            </InlineTypography>
                            <InlineTypography
                                variant={"h4"}
                                fontWeight={"bold"}
                            >
                                &nbsp;Software Engineer&nbsp;
                            </InlineTypography>
                            <InlineTypography variant={"h4"}>
                                with 3+ YoE in developing web/mobile/server apps. My skills include:
                            </InlineTypography>
                            <Stack
                                direction={"row"}
                                flexWrap={"wrap"}
                                rowGap={1}
                                columnGap={1}
                                paddingTop={2}
                            >
                                {skills.map((s, index) => (
                                    <ColorizedChip text={s} key={index} />
                                ))}
                            </Stack>
                        </Box>
                        <Box sx={{ mb: 1 }}>
                            <InlineTypography variant={"h4"}>
                                Btw, if you happen to speak Vietnamese, my
                                Vietnamese name is&nbsp;
                            </InlineTypography>
                            <InlineTypography
                                variant={"h4"}
                                fontWeight={"bold"}
                            >
                                {"Trần Quốc Khôi Nguyên."}
                            </InlineTypography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Cube />
                        <Box>
                            <InlineTypography variant={"h4"} sx={{ mb: 1 }}>
                                Beside programming, I also love playing the electric guitar 🎸.
                                My music style is&nbsp;
                            </InlineTypography>
                            <ColorizedText
                                variant={"h4"}
                                display={"inline-block"}
                            >
                                {"Neo Soul"}
                            </ColorizedText>
                            <InlineTypography variant={"h4"} sx={{ mb: 1 }}>
                                , with a bit of Classical.
                            </InlineTypography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}

export default App;
