// @flow
import * as React from "react";
import { keyframes, styled } from "@mui/material";
import NodeJSLogo from "../images/nodejs-logo.png";
import GitlabLogo from "../images/gitlab-logo.png";
import ReactLogo from "../images/react-logo.png";
import GoLogo from "../images/go-logo.png";
import AWSLogo from "../images/aws-logo.png";
import DockerLogo from "../images/docker-logo.png";

const DEFAULT_WIDTH = 22;

const Logo = styled("img")`
    width: 80%;
    height: 80%;
    object-fit: contain;
`;

const Scene = styled("div")`
    perspective: ${2.5 * DEFAULT_WIDTH}rem;
    margin: 6rem;
    border: none;
`;

const spinCube = keyframes`
  0% {
    transform: translateZ(-100px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: translateZ(-100px) rotateX(0deg) rotateY(480deg);
  }
  100% {
    transform: translateZ(-100px) rotateX(360deg) rotateY(360deg);
  }
`;

const CubeHero = styled("div")`
    width: ${DEFAULT_WIDTH}rem;
    height: ${DEFAULT_WIDTH}rem;
    position: relative;
    transform-style: preserve-3d;
    margin: 0 auto;
    animation: ${spinCube} 8s infinite ease-in-out;
`;

interface CubeFaceProps {
    direction: "front" | "right" | "back" | "left" | "top" | "bottom";
}

const CubeFace = styled("div")`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.4s;
    background: ${(props) => props.theme.palette.primary.main};
    transform: ${({ direction }: CubeFaceProps) => {
        switch (direction) {
            case "front":
                return `rotateY(0deg) translateZ(${DEFAULT_WIDTH / 2}rem)`;
            case "back":
                return `rotateY(180deg) translateZ(${DEFAULT_WIDTH / 2}rem)`;
            case "left":
                return `rotateY(-90deg) translateZ(${DEFAULT_WIDTH / 2}rem)`;
            case "right":
                return `rotateY(90deg) translateZ(${DEFAULT_WIDTH / 2}rem)`;
            case "top":
                return `rotateX(90deg) translateZ(${DEFAULT_WIDTH / 2}rem)`;
            case "bottom":
                return `rotateX(-90deg) translateZ(${DEFAULT_WIDTH / 2}rem)`;
        }
    }};
`;

export default function Cube() {
    return (
        <Scene>
            <CubeHero>
                <CubeFace direction={"front"}>
                    <Logo src={NodeJSLogo} />
                </CubeFace>
                <CubeFace direction={"right"}>
                    <Logo src={GitlabLogo} />
                </CubeFace>
                <CubeFace direction={"back"}>
                    <Logo src={GoLogo} />
                </CubeFace>
                <CubeFace direction={"left"}>
                    <Logo src={ReactLogo} />
                </CubeFace>
                <CubeFace direction={"top"}>
                    <Logo src={AWSLogo} />
                </CubeFace>
                <CubeFace direction={"bottom"}>
                    <Logo src={DockerLogo} />
                </CubeFace>
            </CubeHero>
        </Scene>
    );
}
